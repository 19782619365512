import Team1 from '../../../../assets/iris.png';
import Team2 from '../../../../assets/gsd.png';
import Team3 from '../../../../assets/anthony.jpg';
import Team4 from '../../../../assets/leon.jpg';
import "./team.css";

const data = [
  {
    id: `1_Guest`,
    name: "Iris Au ",
    description: "Chief Executive Officer (CEO)",
    img: Team1,
    linkedIn: "https://www.linkedin.com/in/iris-au-vurg/",
  },
  
  {
    id: `3_Guest`,
    name: "GSD Club",
    description: "Art and Development",
    img: Team2,
    linkedIn: "https://www.linkedin.com/company/gsd-club-agency/",
  },
];

function Team() {
  return (
    <>
      <section id="team" className="section--spacing pb-6 overflow-hidden">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-12 team--content mb-6">
              <h2 className="d-block text-uppercase font-indigo text-center mb-5">
                meet the team
              </h2>
              <p className="text-center">
                The Goddess IRIS team is composed of experienced entrepreneurs,
                artists, blockchain experts, senior marketing specialists, and
                community managers to make the future of the world a reality. We
                are driven by making our industry more progressive for it to
                grow exponentially in the next few years.
              </p>
            </div>
            <div className="col-12 team--wrapper flex-wrap justify-content-center pt-3 pt-lg-5">
              {data.map(({ id, name, description, img, linkedIn }) => (
                <div key={id} className="col-xl-3 col-lg-4 col-12">
                  <div className="team--item">
                    <img src={img} alt="" loading="lazy" className="team--image" />
                    <div className="d-flex flex-column justify-content-between">
                      <h5 className="mb-2 fw-bold team--member-name">
                        {name}
                      </h5>
                      <span className="team--member-description">
                        {description}
                      </span>
                      {linkedIn && (
                        <a
                          className="team--member-link d-block"
                          href={linkedIn}
                          target="_blank"
                          rel="noreferrer"
                        >
                         <i className="fab fa-linkedin-in"></i>
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Team;
