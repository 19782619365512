import { useRef } from "react";

import Image1 from "../../../../assets/AP.jpg";
import Image2 from "../../../../assets/btc.jpg";
import Image3 from "../../../../assets/mi.jpg";
import Image4 from "../../../../assets/mw.jpg";
import Image6 from "../../../../assets/yahoo.jpg";

import "./faq.css";

const faqData = [
  {
    id: 1,
    title: "WHAT IS AN NFT?",
    content:
      "NFT means NON-FUNGIBLE TOKEN. It's a digital asset that represents real-world objects like art, music, in-game items and videos. An NFT is a piece of art that lives on the blockchain. Owning an NFT is just like owning a painting, but instead of owning it physically, you own it virtually, on the blockchain. They are bought and sold online, most frequently with cryptocurrency, and they are generally encoded with the same underlying software as many cryptos.",
  },
  {
    id: 2,
    title: "WHAT ARE GODDESS IRIS?",
    content:
      "IRIS NFT is releasing a collection of 7,777 cartoon-styled, blockchain GODDESS, making the ETHEREUM Network look hot! Each IRIS NFT sports a randomized collection of the Baddest GODDESS, and an assortment of GODDESS IRIS accessories and luxury lifestyle items – ranging from expensive jewelry to sexy outfits, luxury bags, and more!",
  },
  {
    id: 3,
    title: "HOW MUCH DOES EACH GODDESS COST ?",
    content:
      "Each GODDESS will be minted at 0.2222 ETH on main sale minting day on February 22, 2022. However, the pre-sale V2 price (0.1111 ETH) and pre-sale V3 price 0.1777 ETH will be lower than the main sale price.",
  },
  {
    id: 4,
    title: "WHY DID YOU CHOOSE IRIS?",
    content:
      "In Greek mythology Iris was both a personification of the rainbow and a divine messenger from god. The Ancient Greeks combined these two functions in Iris, since, as a meteorological phenomenon, the rainbow appears to connect heaven and earth, which connects the world of the gods with humanity IRIS is also the colored part of the eye that controls the amount of light that enters in. Like your IRIS and our NFT’s, each and every one is unique.",
  },
  {
    id: 5,
    title: "WHERE CAN I BUY GODDESS?",
    content:
      "It will be available on our website for minting and OPENSEA.IO market for buying, selling, and trading afterward!",
  },
  {
    id: 6,
    title: "HOW CAN I MINT MY GODDESS?",
    content:
      "You can mint your NFT directly on our website on the official mint page. All you will need is to use your ETH Wallet to mint your GODDESS.",
  },
  {
    id: 7,
    title: "HOW MANY CAN I MINT IN PRESALE?",
    content: "3 EACH",
  },
  {
    id: 8,
    title: "HOW MANY CAN I MINT IN PUBLIC SALE?",
    content: "7 EACH",
  },
  {
    id: 9,
    title: "WHAT ARE GODDESS IRIS MADE OF ?",
    content:
      "GODDESS IRIS are programmatically generated based on many different assets: Hairstyle, Skin, Clothes, Accessories, and Background.",
  },
  {
    id: 11,
    title: "WHEN WILL YOU DO YOUR GIVEAWAYS?",
    content:
      "Following a FULL IRIS NFTs main sale sellout, we will announce all main sale giveaways winners on our official Instagram live, Discord channel & Twitter. The FULL collection has to be SOLD out for the main sale giveaways to be valid!",
  },
  {
    id: 12,
    title: "HOW CAN I PARTICIPATE IN THE GIVEAWAYS ?",
    content:
      "You have to purchase and own a GODDESS IRIS NFT from either our public presale or our main sale event on February 22, 2022",
  },
  {
    id: 13,
    title: "WHAT IS A CONFIRMED NFT HOLDER ?",
    content:
      "A confirmed NFT holder means an individual that is a confirmed GODDESS IRIS NFT buyer that owns at least one GODDESS IRIS NFT and has provided proof of ownership.",
  },
  {
    id: 14,
    title: "WHERE DO I GO FOR FURTHER LAUNCH INFO ?",
    setHTML: true,
    content: `
    Join our <a href="https://discord.com/invite/8JBrKe93Gu" class='text-white' target="_blank" rel="norefferer">Discord</a> or follow us on <a href="https://twitter.com/irisnftio" class='text-white' target="_blank" rel="norefferer">Twitter</a> for updates. All IRIS NFT purchases will be made at <a href="https://irisnft.io" class='text-white' target="_blank" rel="norefferer">https://irisnft.io</a>
    `,
  },
];

function FAQ() {
  const openedAccordion = useRef(null);

  const handleAccordionClick = (e) => {
    const tg = e.currentTarget;
    const panel = tg.nextElementSibling;

    if (tg.classList.contains("active")) {
      panel.style.maxHeight = null;
      tg.classList.remove("active");
      tg.parentElement.classList.remove("active");
      return;
    }

    if (openedAccordion?.current && tg !== openedAccordion?.current) {
      openedAccordion.current.classList.remove("active");
      openedAccordion.current.nextElementSibling.style.maxHeight = null;
      openedAccordion.current.parentElement.classList.remove("active");
    }

    tg.classList.add("active");
    tg.parentElement.classList.add("active");
    openedAccordion.current = tg;
    panel.style.maxHeight = panel.scrollHeight + "px";
  };

  return (
    <section id="faq" className="overflow-hidden">
      <div className="container">
        <div className="row">
          <h2 className="text-center mb-5 font-indigo">FAQ</h2>
          <div className="faq--container">
            {faqData.map(
              ({ id, title, content, link = "", setHTML = false }) => (
                <div key={`${id}_${title}`} className="accordion--item">
                  <button
                    onClick={handleAccordionClick}
                    className="custom--accordion h2"
                  >
                    {title}
                  </button>
                  <div className="custom--panel">
                    {setHTML ? (
                      <p dangerouslySetInnerHTML={{ __html: content }}></p>
                    ) : (
                      <p>{content}</p>
                    )}
                    {link && (
                      <a
                        href={link}
                        className="text-white"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Here you can find how to setup a wallet
                      </a>
                    )}
                  </div>
                </div>
              )
            )}
          </div>
        </div>
      </div>
      <div className="container section--sacing media--container mt-5">
        <div className="row justify-content-center">
          <h3 className="col-12 text-uppercase text-center h2 fw-bold">
            As seen on
          </h3>
          <div className="col-xl-2 col-md-4 col-sm-6 col-4">
            <a
              href="https://apnews.com/press-release/globe-newswire/technology-entertainment-business-lifestyle-celebrity-3ef383ed05c04fd1156712c0d6de5538"
              className="d-block"
              target="_blank"
              rel="noreferrer"
            >
              <img width="100%" src={Image1} loading="lazy" alt="" />
            </a>
          </div>
          <div className="col-xl-2 col-md-4 col-sm-6 col-4">
            <a
              href="https://news.bitcoin.com/the-nft-universe-now-has-its-first-goddess-iris/"
              target="_blank"
              rel="noreferrer"
            >
              <img width="100%" src={Image2} loading="lazy" alt="" />
            </a>
          </div>
          <div className="col-xl-2 col-md-4 col-sm-6 col-4">
            <a
              href="https://markets.businessinsider.com/news/stocks/iris-world-launches-exclusive-goddess-nft-collection-along-with-kardashian-kloset-1-of-1-nft-1031046334"
              target="_blank"
              rel="noreferrer"
            >
              <img width="100%" src={Image3} loading="lazy" alt="" />
            </a>
          </div>
          <div className="col-xl-2 col-md-4 col-sm-6 col-4">
            <a
              href="https://www.marketwatch.com/press-release/the-nft-universe-now-has-its-first-goddess-iris-2021-12-15"
              target="_blank"
              rel="noreferrer"
            >
              <img width="100%" src={Image4} loading="lazy" alt="" />
            </a>
          </div>
          <div className="col-xl-2 col-md-4 col-sm-6 col-4">
            <a
              href="https://finance.yahoo.com/finance/news/nft-universe-now-first-goddess-161500795.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAAB6TiRJlqcrDGstyU_2tjKtWbp4zLMjrIGi9ac16vpavOmn4LR8SL1FwYFSG21EJAgecJDTqamdSMvRHyTi6gdJziiQ0FLGGykNDblWy61PZBmm2Uxmyv_Y0WB7uwH_gFA6Vz44NqIrGQckZPHIgOww7LvcNFyJ58vz8QTf0tvXe"
              target="_blank"
              rel="noreferrer"
            >
              <img width="100%" src={Image6} loading="lazy" alt="" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FAQ;
