import { useEffect } from "react";
import Kardash from "../../assets/kim.gif";
import { ReactComponent as Opensea } from "../../assets/opensea.svg";

const Kim = () => {
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  return (
    <section className="section--spacing">
      <div className="position-relative">
        <div className="custom--block-with-background container">
          <div className="row align-items-center justify-content-between z4 position-relative">
            <div className="col-lg-5">
              <img src={Kardash} alt="" loading="lazy" />
            </div>
            <div className="col-lg-5">
              <h2 className="mb-3 font-indigo">Kardashian KLOSET NFT</h2>
              <p>
                Ultra rare opportunity and from the personal closet of the
                Kardashian family’s renowned collection of Hermes handbags, this
                Hermes Birkin is presented in the original box with an authentic
                signature from the Kardashians. This is your chance to own a 1
                of 1 NFT and matching bag.
              </p>
              <a 
                href="https://opensea.io/assets/0x495f947276749ce646f68ac8c248420045cb7b5e/38183868602596939199660379378039318296015387159777578160117114746459157168129"
                className="view-on-opensea"
                target="_blank"
                rel="noreferrer"
                >
                  <Opensea />
                  <span className="fw-bold text-white"> VIEW ON OPENSEA</span>
                </a>
            </div>
          </div>
        </div>
        <div className="container">
            <div className="row text-end">
                <h6 className='my-4 text-white'>
                Photo Credit: <a href="https://www.instagram.com/irmalomidze/" target="_blank" rel="noreferrer" className="text-white">Irma Lomidze</a> 
                </h6>
            </div>
        </div>
      </div>
    </section>
  );
};

export default Kim;
