import { useEffect, useState, useRef } from "react";
import { default as HomeSection } from "./sections/Home/";
import RoadMap from "./sections/Roadmap";
import Utility from "./sections/Utility";
import LegendaryItems from "../../components/LegendaryItems";
import Features from "./sections/Features";
import Team from "./sections/Team";
import About from "./sections/About";
import FAQ from "./sections/FAQ";

import Brand from "../../assets/brand.png";

const Loading = () => (
  <div className="loading--container">
    <img src={Brand} alt="" />
  </div>
);

function Home() {
  const timeoutRef = useRef(null);
  const [isFontLoaded, setIsFontLoaded] = useState(true);

  useEffect(() => {
    document.fonts.onloading = () => {
      setIsFontLoaded(false);
    };
    document.fonts.onloadingdone = () => {
      timeoutRef.current = setTimeout(() => {
        setIsFontLoaded(true);
      }, 800);
    };

    return () => {
      clearTimeout(timeoutRef.current);
    };
  });

  if (!isFontLoaded) return <Loading />;

  return (
    <>
      <HomeSection />
      {/* <About />
      <LegendaryItems />
      <RoadMap />
      <Utility/>
      <Features />
      <FAQ /> */}
    </>
  );
}

export default Home;
