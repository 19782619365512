import { useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { ReactComponent as Opensea } from "../../assets/opensea.svg";
import Brand from "../../assets/brand.png";
import "./header.css";

function Header() {
  const activeLinkRef = useRef(null);
  const burgerRef = useRef(null);
  const modalContentRef = useRef(null);
  const location = useLocation();

  const handleBurgerClick = (e) => {
    const target = e.currentTarget;
    if (target.classList.contains("active")) {
      target.classList.remove("active");
      document.documentElement.removeAttribute("style");
      return;
    }
    target.classList.add("active");
    document.documentElement.setAttribute("style", "overflow: hidden");
  };

  const handleLinkClick = (e) => {
    if (activeLinkRef.current?.classList.contains("active")) {
      activeLinkRef.current.classList.remove("active");
    }
    burgerRef.current?.classList.remove("active");

    e.currentTarget.classList.add("active");
    activeLinkRef.current = e.currentTarget;
  };

  const handleOutsideClick = (e) => {
    document.documentElement.removeAttribute("style");
    if (
      (modalContentRef.current &&
        modalContentRef.current?.contains(e.target)) ||
      window.innerWidth > 992
    ) {
      return;
    }
    burgerRef.current.classList.remove("active");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  });

  return (
    <header>
      <nav
        ref={modalContentRef}
        className="navbar navbar-expand-xl w-100 position-relative justify-content-between"
      >
        <Link to="/" className="navbar-brand">
          <img src={Brand} alt="" />
        </Link>
        <div
          ref={burgerRef}
          className="burger_bar_container"
          onClick={handleBurgerClick}
        >
          <div className="bar1 bars"></div>
          <div className="bar2 bars"></div>
          <div className="bar3 bars"></div>
        </div>
        {location.pathname.length <= 1 && (
          <div
            className="navbar-collapse header--content"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 align-items-center flex-grow-1 flex-shrink-0">
              {/* <li className="nav-item">
                <a onClick={handleLinkClick} className="nav-link" href="#root">
                  <span>Home</span>
                </a>
              </li>
              <li className="nav-item">
                <a
                  onClick={handleLinkClick}
                  className="nav-link"
                  href="#project"
                >
                  <span>Project</span>
                </a>
              </li>
              <li className="nav-item nav-item-dropdown">
                <a onClick={handleLinkClick} className="nav-link" href="#">
                  <span>collabs</span>
                </a>
                <div className="nav-dropdown-content p3 bg-white shadow">
                  <Link to="kardashian" className="nav-link dropdown--item">
                    Iris World x Kardashian Kloset
                  </Link>
                </div>
              </li>
              <li className="nav-item">
                <a
                  onClick={handleLinkClick}
                  className="nav-link"
                  href="#roadmap"
                >
                  <span>Roadmap</span>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={handleLinkClick} className="nav-link" href="#team">
                  <span>Team</span>
                </a>
              </li>
              <li className="nav-item">
                <a onClick={handleLinkClick} className="nav-link" href="#faq">
                  <span>FAQ</span>
                </a>
              </li> */}
              <li className="nav--social d-flex d-xl-none mt-2">
                <a
                  className="nav-link nav-link_icon"
                  href="https://www.instagram.com/irisnft.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="nav-link nav-link_icon"
                  href="https://twitter.com/irisnftio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="nav-link nav-link_icon nav-link_opensea"
                  href="https://opensea.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Opensea />
                </a>
                <a
                  href="https://discord.gg/8JBrKe93Gu"
                  target="blank"
                  rel="noreferrer"
                  className="btn btn-primary d-none d-flex-xl"
                >
                  <i className="fab fa-discord"></i>
                  <span className="text-uppercase fw-bold">join discord</span>
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="nav--social d-none d-xl-flex">
          <a
            className="nav-link nav-link_icon"
            href="https://www.instagram.com/irisnft.io"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-instagram"></i>
          </a>
          <a
            className="nav-link nav-link_icon"
            href="https://twitter.com/irisnftio"
            target="_blank"
            rel="noreferrer"
          >
            <i className="fab fa-twitter"></i>
          </a>
          <a
                  className="nav-link nav-link_icon"
                  href="https://t.me/IRISNFT"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-telegram"></i>
                </a>
          <a
            className="nav-link nav-link_icon nav-link_opensea"
            href="https://opensea.io/"
            target="_blank"
            rel="noreferrer"
          >
            <Opensea />
          </a>
          <a
            href="https://discord.gg/8JBrKe93Gu"
            target="blank"
            rel="noreferrer"
            className="btn btn-primary"
          >
            <i className="fab fa-discord"></i>
            <span className="text-uppercase fw-bold">join discord</span>
          </a>
        </div>
      </nav>
    </header>
  );
}

export default Header;
