import {useEffect} from 'react';

const TermsAndConditions = () => {

  useEffect(() =>{
      window?.scrollTo(0,0)
  },[])

  return (
    <div className="container">
      <div className="row">
        <h2 className="fw-bold">TERMS & CONDITIONS</h2>
        <p>
          Goddess Iris is a collection of digital artworks known as NFTs
          (Non-fungible Tokens) running on the Ethereum network. This website is
          only an interface allowing participants to exchange digital
          collectibles. Users are entirely responsible for the safety and
          management of their own private Ethereum wallets and validating all
          transactions and contracts generated by this website before approval.
          Furthermore, as the Goddess Iris smart contract runs on the Ethereum
          network, there is no ability to undo, reverse, or restore any
          transactions.
          <br />
          This website and its connected services are provided “as is” and “as
          available” without a warranty of any kind. By using this website you
          are accepting sole responsibility for any and all transactions
          involving Goddess Iris digital collectibles (NFTs).
        </p>
        <h2>OWNERSHIP</h2>
        <ul>
          <li>
            <p>
              You Own the NFT. Each Goddess Iris is an NFT on the Ethereum
              blockchain. When you purchase an NFT, you own the underlying
              Goddess, the Art, completely. Ownership of the NFT is mediated
              entirely by the Smart Contract and the Ethereum Network: at no
              point may we seize, freeze, or otherwise modify the ownership of
              any Goddess Iris.
            </p>
          </li>
          <li>
            <p>
              Personal Use. Subject to your continued compliance with these
              Terms, Iris World, Inc grants you a worldwide, royalty-free
              license to use, copy, and display the purchased Art, along with
              any extensions that you choose to create or use, solely for the
              following purposes: (i) for your own personal, non-commercial use;
              (ii) as part of a marketplace that permits the purchase and sale
              of your Goddess Iris / NFT, provided that the marketplace
              cryptographically verifies each Goddess Iris owner’s rights to
              display the Art for their Goddess to ensure that only the actual
              owner can display the Art; or (iii) as part of a third-party
              website or application that permits the inclusion, involvement, or
              participation of your Goddess Iris, provided that the
              website/application cryptographically verifies each Goddess Iris
              owner’s rights to display the Art for their Goddess to ensure that
              only the actual owner can display the Art, and provided that the
              Art is no longer visible once the owner of the Goddess Iris leaves
              the website/application.
            </p>
          </li>
          <li>
            Commercial Use. Subject to your continued compliance with these
            Terms, Iris World, Inc grants you an unlimited, worldwide license to
            use, copy, and display the purchased Art for the purpose of creating
            derivative works based upon the Art (“Commercial Use”). Examples of
            such Commercial Use would e.g. be the use of the Art to produce and
            sell merchandise products (T-Shirts etc.) displaying copies of the
            Art. For the sake of clarity, nothing in this Section will be deemed
            to restrict you from (i) owning or operating a marketplace that
            permits the use and sale of Goddess Iris generally, provided that
            the marketplace cryptographically verifies each Goddess Iris owner’s
            rights to display the Art for their Goddess to ensure that only the
            actual owner can display the Art; (ii) owning or operating a
            third-party website or application that permits the inclusion,
            involvement, or participation of Goddess Iris generally, provided
            that the third-party website or application cryptographically
            verifies each Goddess Iris owner’s rights to display the Art for
            their Goddess to ensure that only the actual owner can display the
            Art, and provided that the Art is no longer visible once the owner
            of the Purchased Goddess Iris leaves the website/application; or
            (iii) earning revenue from any of the foregoing.
          </li>
        </ul>
        <h2>GIVEAWAYS</h2>
        When you mint a Goddess Iris NFT, you automatically get a chance to win
        a brand new Lamborghini Huracan EVO and a brand new Tesla Model 3
        Performance. The winner can choose between a Lamborghini Huracan EVO at
        a maximum listed price of $250 000 US dollars or $250 000 US dollars
        worth of ETH. The winner can choose between a Tesla Model 3 Performance
        at a maximum listed price of $60,000 US dollars or $60,000 US dollars
        worth of ETH.
        <br />
        <br />
        When you mint a Goddess Iris NFT, you automatically get a chance to win
        a brand new Rolex of your choice. Rolex watch must be an in-stock item
        as seen on{" "}
        <a href="https://www.mayors.com/rolex">
          https://www.mayors.com/rolex
        </a>{" "}
        with a value of $8,000 or less. The winner also can also choose to be
        paid $8,000 in ETH if the winner chooses not to accept this prize.
        <br />
        <br />
        When you mint a Goddess Iris NFT, you automatically get a chance to win
        $7,777 worth of ETH. Seven (7) winners will be chosen.
      </div>
    </div>
  );
};

export default TermsAndConditions;
