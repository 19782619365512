import { Link } from "react-router-dom";
import { ReactComponent as Opensea } from "../../assets/opensea.svg";
import FooterSpline from "../../assets/footer_spline.png";
import Brand from "../../assets/brand_sm.png";

import "./footer.css";

function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row h-100 w-100 align-items-center">
          <div className="col-12 d-flex flex-wrap align-items-center">
            <a href="#root" className="navbar-brand">
              <img src={Brand} alt="Goddess Iris Brand Logo" />
            </a>
            <div className="footer--social-container ms-6">
              <h2 className="text-uppercase mb-4 font-indigo">GET A GODDESS TODAY</h2>
              <div className="d-flex align-items-center nav--social">
                <a
                  className="nav-link nav-link_icon"
                  href="https://www.instagram.com/irisnft.io"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  className="nav-link nav-link_icon"
                  href="https://twitter.com/irisnftio"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  className="nav-link nav-link_icon"
                  href="https://discord.gg/8JBrKe93Gu"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fab fa-discord"></i>
                </a>
                <a
                  className="nav-link nav-link_icon nav-link_opensea"
                  href="https://opensea.io/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Opensea />
                </a>
                <a
                  href="https://discord.gg/8JBrKe93Gu"
                  target="blank"
                  rel="noreferrer"
                  className="btn btn-primary"
                >
                  <i className="fab fa-discord"></i>
                  <span className="text-uppercase fw-bold">join discord</span>
                </a>
              </div>
              
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-section">
        <div className="container">
          <div className="row align-items-center">
            <p className="col-6">Copyright © 2021 All Rights Reserved</p>
            <Link to="/terms-and-conditions" className='h5 text-white col-6 m-0 text-end'>
              <p>Terms and conditions</p>
            </Link>
          </div>
        </div>
      </div>
      {/* <div className="footer--bg">
        <img src={FooterSpline} alt="" />
      </div> */}
    </footer>
  );
}

export default Footer;
