import React from "react";
import ReactDOM from "react-dom";
import { Helmet } from "react-helmet";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "@fortawesome/fontawesome-free/js/all";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import TermsAndConditions from "./pages/TermsAndConditions";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Kim from "./pages/Kim";
import reportWebVitals from "./reportWebVitals";
import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

function getLibrary(provider) {
  console.log(provider, "Provider");
  const library = new Web3Provider(provider);
  library.pollingInterval = 12000;
  return library;
}

ReactDOM.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Goddess Iris</title>
        <meta name="description" content="Goddess Iris Website. " />
      </Helmet>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path="/" element={<App />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/kardashian" element={<Kim />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </Web3ReactProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
