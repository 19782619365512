import Banner from "../../../../assets/banner.png";

import "./home.css";

function Home() {
  return (
    <section
      id="home"
      className="position-relative content-inner d-flex align-items-end justify-content-center"
    >
      <img src={Banner} alt="Shiba Social Club" />
      <div className="home--banner">
        <h1 className="text-uppercase text-center font-indigo">
          ComIng soon
        </h1>
      </div>
        {/* <a 
        href="#mint" 
        className="btn btn-primary d-inline-flex gleam--link text-uppercase fw-bold px-5"
        >
          presale coming soon
        </a> */}
    </section>
  );
}

export default Home;
